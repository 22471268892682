<script>
// import api from "@/api"

export default {
  name: "",
  data() {
    return {};
  },
  methods: {
    // getAdmins () {
    //   var parent_ = this
    //   var url = '/api/manager/Admins/'
    //   api.Manager.axios.get(url)
    // }
  },
  created() {
    // this.getAdmins();
  },
};
</script>

<template>
  <div id="Admins">Admins</div>
</template>

<style lang="scss">
#Admins {
}
</style>
